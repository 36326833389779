/* src/components/Stats/Stats.css */

/* Global Styles */
* {
  box-sizing: border-box;
}

.stats-container {
  color: #1EB62D;
  margin-top: 20px;
  width: 100%;
  font-family: 'Tomorrow', sans-serif;
}

/* Points Display */
.points-display {
  font-size: 64px;
  font-family: 'Nyxerin', sans-serif;
  color: #1EB62D;
  padding-top: 50px;
  text-align: center;
}

/* Stats Grid */
.stats-grid {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
  padding-top: 50px;
  padding-bottom: 50px;

}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
}

.circle-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Nyxerin', sans-serif;
  color: #1EB62D;
}

.stat-number {
  font-size: 64px;
  font-family: 'Nyxerin', sans-serif;

}

.stat-text {
  font-size: 20px;
  margin-top: 5px;
}

/* Separator */
.separator {
  margin: 30px;
  font-size: 36px;
  color: #1EB62D;
  text-align: center;
}

.separator .highlight {
  font-family: 'Nyxerin', sans-serif;
  font-size: 60px;
  margin-left: 30px;
  margin-right: 30px;
  color: #1EB62D;
}

/* Goals and Assists Stats */
.ga-stats-section {
  display: flex;
  justify-content: center;
  gap: 150px;
  margin: 50px;
}

.ga-stats-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Nyxerin', sans-serif;
}

.ga-stats-item .stat-value {
  font-size: 60px;
  color: #1EB62D;
}

.ga-stats-item .stat-label {
  font-family: 'Tomorrow', sans-serif;
  font-size: 36px;
  color: rgba(30, 182, 45, 0.8);
  margin: 20px;
}


/* Filter Buttons */
.filter-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 50px 0;
}

.no-points-msg {
  padding-bottom: 50px
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .stats-grid,
  .ga-stats-section {
    /* flex-direction: column; */
    align-items: center;
    gap: 0px
  }

  .stat-item {
    margin: 10px;
    width: 100px; /* Further reduce circle size for very small screens */

  }

  .stat-number {
    font-size: 30px;
  }

  .stat-text {
    font-size: 16px;
  }

  .separator {
    font-size: 24px;
  }

  .separator .highlight {
    font-size: 36px;
  }

  .ga-stats-item .stat-value {
    font-size: 36px;
  }

  .ga-stats-item .stat-label {
    font-size: 24px;
  }
  .points-display{
    font-size: 24px;
  }
  .point-progress-chart {
    height: auto;
  }
}
