.home-container {
  margin: 0;
  padding: 0;
  background-color: #000d1c;
  text-align: center;
}

/* Hero section with background image */
.hero-section {
  position: relative;
  width: 100%;
  height: 600px;
  background-image: url('../../assets/images/home_background.avif');
  background-size: cover;
  background-position: center;
  margin-bottom: 80px;
  display: flex;
  align-items: flex-end; /* Align items to the bottom */
  justify-content: center;
}

/* Gradient overlay using ::before pseudo-element */
.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Adjusted gradient for smoothness */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 13, 28, 1) 90%
  );
  z-index: 1;
}

/* Noise effect overlay */
.noiseeffect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: url('../../assets/images/noise_home_background.avif');
  background-size: cover;
  background-position: center;
  opacity: 1;
  pointer-events: none;
}

/* Hero text */
.hero-text {
  z-index: 3;
  color: #1bc429;
  font-family: 'Nyxerin', sans-serif;
  text-align: center;
  margin-bottom: 0px; 
}

.hero-text h1 {
  font-size: 150px;
  font-family: 'Nyxerin', sans-serif;
  font-weight: normal;
  margin-bottom: 0px;
}

.hero-text p {
  font-size: 32px;
  font-family: 'Tomorrow', sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Upcoming games section */
.upcoming-games {
  text-align: center;
  color: #1bc429;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .hero-section {
    height: 400px;
  }

  .hero-text h1 {
    font-size: 80px;
  }

  .hero-text p {
    font-size: 24px;
  }

  /* Adjust gradient stops for smaller screens if necessary */
  .hero-section::before {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 13, 28, 1) 90%
    );
  }
}

@media screen and (max-width: 480px) {
  .hero-section {
    height: 300px;
  }

  .hero-text h1 {
    font-size: 50px;
  }

  .hero-text p {
    font-size: 18px;
  }


  /* Adjust gradient stops for smaller screens if necessary */
  .hero-section::before {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 13, 28, 1) 90%
    );
  }
}
