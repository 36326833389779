@font-face {
  font-family: 'Nyxerin';
  src: url('./assets/fonts/NYXERIN.woff') format('woff'),
       url('./assets/fonts/NYXERIN.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: block;

}

@font-face {
  font-family: 'Tomorrow';
  src: url('./assets/fonts/Tomorrow-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;

}

/* Specific classes for each font */
.nyxerin-text {
  font-family: 'Nyxerin', sans-serif;
}

.tomorrow-text {
  font-family: 'Tomorrow', sans-serif;
}

/* Define CSS variables or set fonts globally */
:root {
  --font-primary: 'Tomorrow', sans-serif;
  --font-secondary: 'Nyxerin', sans-serif;
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal overflow */
  
}

body {
  display: flex;
  flex-direction: column;
  font-family: var(--font-primary);
  min-height: 100vh;
}

/* Remove default background color of input suggestions in Chrome/Edge */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #000d1c inset; /* Match the background color of your form */
  -webkit-box-shadow: 0 0 0px 1000px #000d1c inset; /* For older versions of Chrome */
  -webkit-text-fill-color: #1BC429; /* Match the text color */
  transition: background-color 5000s ease-in-out 0s;
}

/* Customize the autocomplete dropdown suggestion box */
input::-webkit-autocomplete {
  background-color: transparent !important; /* Makes the dropdown transparent */
  color: #1BC429 !important; /* Matches the text color */
}


.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the App container covers the full height */
  background-color: #000d1c; /* Dark background */

}

.main-content {
  flex-grow: 1; /* This makes the content take up the remaining space */
}

.App > * {
  flex-shrink: 0; /* Prevents components from shrinking and creating extra space */
}
