/* Main layout */
.login-layout {
  display: flex;
  align-items: flex-start; /* Align items to the top */
  background-color: #000d1c;
}

/* Image container */
.login-image-container {
  flex: 0 0 50%; /* Fixed width */
  height: 100vh; /* Fixed height */
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.login-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.login-image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:
    linear-gradient(
      to left,
      rgba(0, 13, 28, 1) 0%,
      rgba(0, 0, 0, 0) 40%
    ),
    linear-gradient(
      to top,
      rgba(0, 13, 28, 1) 20%,
      rgba(0, 0, 0, 0) 40%
    );
  z-index: 1;
  pointer-events: none;
}

.noise-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: url('../../assets/images/noise_home_background.avif');
  background-size: cover;
  opacity: 0.5;
}

/* Form container */
.login-form-container {
  flex: 1; /* Fill remaining width */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center;
  background-color: #000d1c;
  z-index: 3;
  padding-top: 80px; /* Adjust as needed */
}

.login-title {
  font-size: 35px;
  color: #1BC429;
  font-family: "Tomorrow", sans-serif;
  text-align: center;
}

/* Form */
.login-form {
  width: 100%;
  max-width: 400px;
}

/* Form Fields */
.login-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.login-field span {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #1BC429;
  font-family: "Tomorrow", sans-serif;
}

.login-input-line {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #1BC429;
  color: #1BC429;
  font-size: 1rem;
  outline: none;
  width: 100%;
}

.login-input-line::placeholder {
  color: #2C5930;
}

/* Password Container */
.password-container {
  position: relative;
  width: 100%;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #1BC429;
  cursor: pointer;
  font-size: 16px;
}

/* Login Message */
.login-message {
  margin-top: 20px;
  font-size: 14px;
  color: #ff4b4b;
  text-align: center;
}

.login-link {
  text-align: center;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.login-link span {
  color: rgba(255, 255, 255, 0.7);
}

.login-link-text {
  color: #1BC429;
  text-decoration: none;
}

.login-link-text:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* Layout stacking */
  .login-layout{
    flex-direction: column;
    align-items: center; /* Centers items */
  }

  .login-image-container{
    width: 100%;
    height: 40vh;
    flex: none;
  }

  /* Container widths and padding */
  .login-form-container{
    max-width: 100%; 
    margin: 0 auto;
    padding: 20px;
    justify-content: flex-start;
    align-items: center; 
  }

  /* Form widths */
  .login-form{
    max-width: 100%; 
  }

  /* Title sizes */
  .login-title{
    font-size: 28px;
  }

}
