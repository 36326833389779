.leaderboard-container {
  color: #1EB62D;
  font-family: 'Tomorrow', sans-serif;
  width: 100%;
  max-width: 800px; /* Limit the maximum width */
  margin: 0 auto;  /* Center the container horizontally */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  justify-content: center; /* Center the content vertically */
  box-sizing: border-box;
}

/* Search Box (matching your login fields style) */
.search-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #1BC429;
  color: #1BC429;
  font-size: 1rem;
  font-family: 'Tomorrow', sans-serif;
  outline: none;
  width: 50%;
  transition: background-color 0.3s;
  padding: 6px 0; /* Just a bottom border, no side borders */
}

.search-input::placeholder {
  color: #2C5930;
}

/* Ranking Container */
.ranking {
  width: 100%; /* Ensure it uses full container width */
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
}

/* Individual Row */
.ranking-item {
  display: flex;
  align-items: center;
  justify-content: center; /* Center content within each item */
  padding: 10px;
  border-bottom: 1px solid rgba(30, 182, 45, 0.2);
  transition: background-color 0.3s;
}

.ranking-item:hover {
  background-color: rgba(30, 182, 45, 0.1);
}

/* Highlight current user row */
.highlight {
  background: linear-gradient(
    180deg,
    rgb(0, 13, 28) 2%,
    rgb(13.5, 104.5, 34.5) 50%,
    rgb(27, 196, 41) 100%
  );
}

.rank {
  width: 40px;
  text-align: center;
  margin-right: 15px;
  font-size: 20px;
}

/* Avatar styling */
.avatar {
  width: 91.08px;
  height: 84.905px;
  flex-shrink: 0;
  z-index: 0;
  object-fit: cover;
  clip-path: path('M30.518 0.371094C38.5885 0.371094 45.9236 3.52017 51.3614 8.65661L51.3613 8.65625C58.1053 15.0244 61.8363 14.6753 65.0203 11.3034L65.0208 11.3059C67.7882 8.37554 71.7098 6.54688 76.0586 6.54688C84.442 6.54688 91.2382 13.343 91.2382 21.7265C91.2382 28.3504 86.9955 33.9834 81.0793 36.0562C75.0301 38.4159 72.1748 42.5345 75.6515 52.1692C76.7478 54.9298 77.3504 57.9401 77.3504 61.0912C77.3504 74.4482 66.5224 85.2762 53.1654 85.2762C41.7769 85.2762 32.2269 77.4045 29.6594 66.8052C28.7444 63.4188 27.9731 62.0293 24.8175 60.7903C15.3224 60.8733 14.9982 68.2945 16.0842 70.7221C16.5769 71.6769 16.8551 72.7605 16.8551 73.909C16.8551 77.7528 13.7391 80.8688 9.89533 80.8688C6.05155 80.8688 2.93555 77.7528 2.93555 73.909C2.93555 70.5594 5.3019 67.7624 8.45409 67.0986C11.2169 66.3945 17.5667 60.7046 9.0639 52.2129L9.0656 52.2137C3.56262 46.7185 0.158203 39.1222 0.158203 30.7309C0.158203 13.9636 13.7507 0.371094 30.518 0.371094Z');
}

.name {
  flex-grow: 1;
  font-size: 18px;
  text-align: center; /* Center the name text */
}

.points {
  font-weight: bold;
  font-size: 16px;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  flex-direction: column; /* Place page number on top, then buttons below */
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  gap: 0px; /* Slight gap between page number and buttons row */
}

.pagination-info {
  font-family: 'Tomorrow', sans-serif;
  color: #1EB62D;
  font-size: 16px;
}

.pagination-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space between the Previous/Next buttons */
}


/* Responsive styles */
@media (max-width: 768px) {
  .leaderboard-container {
    width: 100%;
    padding: 0 20px; /* 20px padding on left and right */
    box-sizing: border-box; /* Ensure padding is included in width */
  }

  .search-input {
    width: 80%; /* Make search field wider on small screens */
  }

  .ranking-item {
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust item layout for better spacing */
    padding: 8px;
  }

  .rank {
    font-size: 14px;
    margin-right: 8px;
  }

  .name {
    font-size: 14px;
  }

  .points {
    font-size: 14px;
    margin-top: 5px;
  }
}
