/* Profile.css */

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure the container takes up the full viewport height */
  background-color: #000d1c;
  color: #ffffff;
}
