/* UpcomingGames.css */

/* Container for the upcoming games */
.upcoming-games-container {
  text-align: center;
  color: #1EB62D;
  padding: 50px 0;
  background-color: #000d1c;
}

/* Tab menu for "REGISTERED" & "WAITLIST" */
.upcoming-tab-menu {
  display: flex;
  justify-content: center;
  gap: 150px;
  font-family: 'Tomorrow', sans-serif;
  font-size: 36px;
  margin-bottom: 20px;
}

/* Each tab item */
.upcoming-tab-menu span {
  cursor: pointer;
  transition: color 0.3s ease;
}

.upcoming-tab-menu span.active {
  color: #1EB62D;
}

.upcoming-tab-menu span:not(.active) {
  color: rgba(30, 182, 45, 0.3);
}

/* Carousel header (if used) */
.carousel-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

/* Title inside the carousel header */
.carousel-title {
  font-family: 'Tomorrow', sans-serif;
  font-size: 24pt;
  color: #1EB62D;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
}

/* -----------------------------------------
   Responsive Adjustments
----------------------------------------- */

/* Tablet screens */
@media (max-width: 768px) {
  .upcoming-games-container {
    padding: 30px 0; /* reduce vertical padding */
  }

  .upcoming-tab-menu {
    flex-wrap: wrap; /* allow wrapping to avoid cut-off */
    gap: 30px;       /* reduce gap from 150px */
    font-size: 28px; /* slightly smaller text */
    margin-bottom: 15px;
  }

  .carousel-title {
    font-size: 20pt; /* slightly smaller title */
    margin-bottom: 10px;
  }
}

/* Small phones */
@media (max-width: 480px) {
  .upcoming-games-container {
    padding: 20px 0; /* further reduce vertical padding */
  }

  .upcoming-tab-menu {
    gap: 10px;
    font-size: 18px; /* smaller text so "REGISTERED" fits */
    margin-bottom: 10px;
  }

  .carousel-title {
    font-size: 16pt; /* smaller title for narrow screens */
  }
}
