.profile-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* Fallback for older browsers */
  min-height: 100vh;
  /* Modern viewport unit for iOS Safari & co. */
  min-height: 100svh;
  height: auto;
  margin: 0 auto;
  padding: 0;
  background: linear-gradient(
    180deg,
    rgb(27, 196, 41) 2%,
    rgb(13.5, 104.5, 34.5) 50%,
    rgb(0, 13, 28) 100%
  );
  position: relative;
  overflow: hidden;
}

.noiseeffect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url('../../assets/images/noise_home_background.avif');
  background-size: cover;
  background-position: center;
  opacity: 1;
  pointer-events: none;
}

.profile-info {
  display: flex;
  align-items: center;
  z-index: 3;
}

/* ===== Desktop shape: 459.102×428.719 ===== */
.profile-image {
  position: relative;
  width: 459.102px;
  height: 428.719px;
  z-index: 4;
  cursor: pointer;
}

/* Desktop path on the final <img> */
.profile-image img {
  width: 100%;
  height: 100%;
  z-index: 4;
  object-fit: cover;
  clip-path: path("M153.032 0.744141C193.713 0.744141 230.687 16.618 258.097 42.5097L258.097 42.5081C292.09 74.6073 310.897 72.8477 326.946 55.8512L327.004 56.1437C340.951 41.4066 360.695 32.2128 382.587 32.2128C424.845 32.2128 459.102 66.4696 459.102 108.728C459.102 143.497 435.91 172.85 404.153 182.161C376.248 194.303 363.738 215.317 380.546 261.872C386.064 275.778 389.097 290.941 389.097 306.812C389.097 374.14 334.518 428.719 267.19 428.719C209.797 428.719 161.667 389.058 148.714 335.647C144.097 318.551 140.215 311.541 124.296 305.292C76.3543 305.713 74.7998 343.25 80.3086 355.418C82.7718 360.216 84.1618 365.655 84.1618 371.419C84.1618 390.794 68.4553 406.501 49.0802 406.501C29.7052 406.501 13.9987 390.794 13.9987 371.419C13.9987 354.541 25.9185 340.446 41.7996 337.094C55.6976 333.571 87.69 304.953 45.0568 262.22C17.2255 234.509 0 196.154 0 153.776C0 69.2588 68.5147 0.744141 153.032 0.744141Z");
}

.profile-image .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.profile-image:hover .overlay {
    opacity: 1;
    
}

/* Style for the "Update Picture" text */
.profile-image .overlay span {
    position: absolute;
    right: 20px;        /* 20px from the right edge */
    top: 0%;           /* Vertically center */
    transform: translateY(-50%); /* Adjust for vertical centering */
    color: #000D1C; 
    font-size: 16px;
    font-family: 'Tomorrow', sans-serif;
    z-index: 6;
}
.profile-details {
    margin-left: 50px;
    text-align: left;
    z-index: 3;
}

.profile-details .first-name {
    font-size: 64px;
    font-family: 'Nyxerin', sans-serif;
    margin-bottom: 10px;
    color: #000D1C;
}

.profile-details p {
    font-size: 20px;
    margin: 5px 0;
    color: #000D1C;
}

.profile-details p:last-child {
    font-family: 'Nyxerin', sans-serif;
    font-size: 28px;
    color: #000D1C;
    margin-top: 10px;
}

.profile-edit-btn {
    position: absolute;
    top: 30px;
    right: 50px;
    z-index: 4;
}

.crop-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden;
}

/* Container matches final shape size */
.crop-container {
  position: relative;
  width: 459.102px;
  height: 428.719px;
  overflow: hidden;
  clip-path: path("M153.032 0.744141C193.713 0.744141 230.687 16.618 258.097 42.5097L258.097 42.5081C292.09 74.6073 310.897 72.8477 326.946 55.8512L327.004 56.1437C340.951 41.4066 360.695 32.2128 382.587 32.2128C424.845 32.2128 459.102 66.4696 459.102 108.728C459.102 143.497 435.91 172.85 404.153 182.161C376.248 194.303 363.738 215.317 380.546 261.872C386.064 275.778 389.097 290.941 389.097 306.812C389.097 374.14 334.518 428.719 267.19 428.719C209.797 428.719 161.667 389.058 148.714 335.647C144.097 318.551 140.215 311.541 124.296 305.292C76.3543 305.713 74.7998 343.25 80.3086 355.418C82.7718 360.216 84.1618 365.655 84.1618 371.419C84.1618 390.794 68.4553 406.501 49.0802 406.501C29.7052 406.501 13.9987 390.794 13.9987 371.419C13.9987 354.541 25.9185 340.446 41.7996 337.094C55.6976 333.571 87.69 304.953 45.0568 262.22C17.2255 234.509 0 196.154 0 153.776C0 69.2588 68.5147 0.744141 153.032 0.744141Z");
}

.zoom-slider-container {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  z-index: 1000;
}

.zoom-slider {
  outline: none;
  cursor: pointer;
  background: transparent;
}

.crop-controls {
  position: absolute;
  bottom: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.crop-modal * {
  user-select: none;
}

.crop-modal .reactEasyCrop_CropArea {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background: transparent !important;
}

  /* === Mobile: up to 768px, we use the smaller path (200×200) === */
@media (max-width: 768px) {
  .profile-info-container {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding-top: 80px !important;
    padding-bottom: 0 !important;
    margin: 0 !important;
    background: linear-gradient(
      180deg,
      rgb(27, 196, 41) 2%,
      rgb(14, 105, 35) 85%,
      rgba(0, 13, 28) 100%
    ) !important;
    min-height: auto !important;
    height: auto !important;
  }

  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0;
  }

  .profile-image-wrapper, 
  .profile-image, 
  .profile-details {
    margin: 0 !important;
    padding: 0 !important;
  }

  .profile-image-wrapper {
    width: 100%;
    text-align: center !important;
    align-self: center !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  /* Now we set EXACT 200×200 to match that smaller path’s bounding box */
  .profile-image {
    width: 200px !important;
    height: 200px !important;
    margin: 0 auto !important;
    padding-left: 0 !important;
  }

  .profile-image img {
    clip-path: path("M66.6658 0C84.3879 0 100.495 7.41813 112.436 19.5177L112.436 19.517C127.244 34.5175 135.437 33.6952 142.429 25.7524L142.454 25.8891C148.529 19.0022 157.131 14.7058 166.668 14.7058C185.077 14.7058 200 30.7146 200 50.4624C200 66.7107 189.897 80.4277 176.062 84.7791C163.906 90.4533 158.456 100.273 165.779 122.029C168.183 128.528 169.504 135.614 169.504 143.031C169.504 174.494 145.727 200 116.397 200C91.3946 200 70.4275 181.465 64.7846 156.506C62.7734 148.516 61.0822 145.241 54.1477 142.32C33.2624 142.517 32.5853 160.059 34.9851 165.745C36.0581 167.987 36.6637 170.529 36.6637 173.223C36.6637 182.277 29.8214 189.617 21.381 189.617C12.9406 189.617 6.09829 182.277 6.09829 173.223C6.09829 165.335 11.2911 158.748 18.2095 157.182C24.2641 155.535 38.2007 142.161 19.6277 122.192C7.50381 109.241 0 91.3179 0 71.5143C0 32.0181 29.8473 0 66.6658 0Z");
  }

  /* Crop container also 200×200 */
  .crop-container {
    width: 200px !important;
    height: 200px !important;
    clip-path: path("M66.6658 0C84.3879 0 100.495 7.41813 112.436 19.5177L112.436 19.517C127.244 34.5175 135.437 33.6952 142.429 25.7524L142.454 25.8891C148.529 19.0022 157.131 14.7058 166.668 14.7058C185.077 14.7058 200 30.7146 200 50.4624C200 66.7107 189.897 80.4277 176.062 84.7791C163.906 90.4533 158.456 100.273 165.779 122.029C168.183 128.528 169.504 135.614 169.504 143.031C169.504 174.494 145.727 200 116.397 200C91.3946 200 70.4275 181.465 64.7846 156.506C62.7734 148.516 61.0822 145.241 54.1477 142.32C33.2624 142.517 32.5853 160.059 34.9851 165.745C36.0581 167.987 36.6637 170.529 36.6637 173.223C36.6637 182.277 29.8214 189.617 21.381 189.617C12.9406 189.617 6.09829 182.277 6.09829 173.223C6.09829 165.335 11.2911 158.748 18.2095 157.182C24.2641 155.535 38.2007 142.161 19.6277 122.192C7.50381 109.241 0 91.3179 0 71.5143C0 32.0181 29.8473 0 66.6658 0Z");
  }

  /* Center text in details on mobile */
  .profile-details {
    text-align: center !important;
    padding-bottom: 50px !important;
  }

  /* Hide or reposition the zoom slider if you like */
  .zoom-slider-container {
    display: none;
  }
  .profile-details .first-name {
    font-size: 48px;
    padding-top: 30px;
  }
}
