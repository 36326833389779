.about-us-container {
  background-color: #000d1c;
  color: #1BC429;
  font-family: 'Tomorrow', sans-serif;
  padding: 2rem 0;
}

.section {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0;
  justify-content: center;
}

.section-content {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.section-title {
  font-size: 2rem;
  margin: 0 0 1rem 0;
  text-align: center;
  width: fit-content;
  align-self: center;
}

.section-text {
  font-size: 1.2rem;
  line-height: 1.8;
  padding: 0 20%;
}

/* WHO WE ARE (section-first) */
.section-first {
  flex-direction: row-reverse;
  align-items: center;
}
.section-first .section-image-container {
  flex: 1;
  position: relative;
  padding: 0;
}
.section-first .section-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.section-first .section-image-container::before {
  content: '';
  position: absolute;
  top: 0; right: 0;
  width: 100%; height: 100%;
  background: 
    linear-gradient(to right, rgba(0, 13, 28, 1) 0%, rgba(0, 0, 0, 0) 40%),
    linear-gradient(to top, rgba(0, 13, 28, 1) 20%, rgba(0, 0, 0, 0) 40%);
  z-index: 2;
  pointer-events: none;
}

/* OUR MISSION (section-second) */
.section-second {
  flex-direction: column;
  padding-bottom: 3rem;
}
.section-second .section-image-container {
  width: auto;
  margin: 1rem;
  text-align: center;
}
.section-second .section-image {
  max-width: 50%;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
}
.section-second .section-text {
  padding: 0 25%;
}

/* HOW DOES IT WORK (section-third) */
.section-third {
  flex-direction: row;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.section-third .section-image-container {
  flex: 1;
  position: relative;
  padding: 0;
}
.section-third .section-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section-third .section-title {
  font-family: 'Nyxerin', sans-serif;
  font-size: 2rem;
}
.section-third .section-content {
  padding: 2rem;
}
.section-third .section-text {
  padding: 0 10%;
}
.section-third .section-image-container::before {
  content: '';
  position: absolute;
  top: 0; right: 0;
  width: 100%; height: 100%;
  background: 
    linear-gradient(to left, rgba(0, 13, 28, 1) 0%, rgba(0, 0, 0, 0) 40%),
    linear-gradient(to top, rgba(0, 13, 28, 1) 20%, rgba(0, 0, 0, 0) 40%);
  z-index: 2;
  pointer-events: none;
}

/* -------------------------
   NEW MEDIA QUERY OVERRIDES
   ------------------------- */
@media (max-width: 768px) {
  /* Hide first two sections completely */
  .section-first,
  .section-second {
    display: none;
  }

  /* In the third section, hide the image container so only text remains */
  .section-third .section-image-container {
    display: none;
  }
  .about-us-container {
    padding: 0;
  }
  
}
