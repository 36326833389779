/* LineChart.css */

/* Container for the line chart */
.progress-chart-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  padding: 40px;
  min-height: 400px;   /* ensures some vertical space */
  box-sizing: border-box;
  background-color: transparent;
}

/* Chart Title */
.chart-title {
  text-align: center;
  color: #1EB62D;
  font-family: 'Tomorrow', sans-serif;
  font-size: 24px;
  margin-bottom: 10px;
}

/* Line Chart for Point Progression */
.point-progress-chart {
  width: 100%;
  height: 400px;
}


/* Responsive tweaks for smaller screens */
@media (max-width: 768px) {
  .progress-chart-container {
    height: 250px; /* Adjust height for tablets */
  }

  .chart-title {
    font-size: 20px; /* Smaller title font size */
  }
}

@media (max-width: 480px) {
  .progress-chart-container {
    height: 250px; /* Further reduce height for mobile screens */
  }

  .chart-title {
    font-size: 16px; /* Reduce font size for very small screens */
  }
}