/* Main layout */
.register-layout {
  display: flex;
  align-items: flex-start; /* Align items to the top */
  background-color: #000d1c;
}

/* Image container */
.register-image-container {
  flex: 0 0 50%; /* Fixed width */
  height: 100vh; /* Fixed height */
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.register-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.register-image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:
    linear-gradient(
      to left,
      rgba(0, 13, 28, 1) 0%,
      rgba(0, 0, 0, 0) 40%
    ),
    linear-gradient(
      to top,
      rgba(0, 13, 28, 1) 20%,
      rgba(0, 0, 0, 0) 40%
    );
  z-index: 1;
  pointer-events: none;
}

.noise-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: url('../../assets/images/noise_home_background.avif');
  background-size: cover;
  opacity: 0.5;
}

/* Form container */
.register-form-container {
  flex: 1; /* Fill remaining width */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000d1c;
  z-index: 3;
  padding-top: 80px; /* Adjust as needed */
}

.register-title {
  font-size: 35px;
  color: #1BC429;
  font-family: "Tomorrow", sans-serif;
  text-align: center;
}

/* Adjust Form Spacing */
.register-form {
  width: 100%;
  max-width: 400px;
}

/* Adjust Field Spacing */
.register-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.register-field :last-child {
  margin: 0px;
}

.register-field span :last-child {
  margin: 0px;
}

.register-field span {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #1BC429;
  font-family: "Tomorrow", sans-serif;
}

.register-input-line {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #1BC429;
  color: #1BC429;
  font-size: 1rem;
  outline: none;
  width: 100%;
}

.register-input-line::placeholder {
  color: #2C5930;
  font-family: "Tomorrow", sans-serif;
}

.password-container {
  position: relative;
  width: 100%;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #1BC429;
  cursor: pointer;
  font-size: 16px;
}

.register-message {
  margin-top: 20px;
  font-size: 14px;
  color: #ff4b4b;
  text-align: center;
}

/* Login link */
.login-link {
  text-align: center;
  font-size: 0.9rem;
}

.login-link span {
  color: #2C5930;
}

.login-link-text {
  color: #1BC429;
  text-decoration: none;
}

.login-link-text:hover {
  text-decoration: underline;
}

.phone-code-select {
  padding: 5px;
  border: 2px solid #1BC429;
  border-radius: 3px;
  font-size: 1rem;
  background-color: #000d1c;
  color: #1BC429;
  margin-right: 10px;
  width: 20%;
  font-family: "Tomorrow", sans-serif;
}

.phone-input-container {
  display: flex;
  align-items: center;
}

.phone-input-container input {
  flex: 1;
}

.button-row {
  display: flex;
  gap: 20px;
}

/* Privacy Checkbox in Registration */
.register-field.privacy-policy-checkbox {
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-direction: row;
}

.register-field.privacy-policy-checkbox input[type="checkbox"] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  accent-color: #1BC429; /* For modern browsers that support accent-color */
}

.register-field.privacy-policy-checkbox label {
  font-size: 0.9rem;
  color: #1BC429;
  cursor: pointer;
}

.register-field.privacy-policy-checkbox .privacy-link {
  color: #1BC429;
  text-decoration: underline;
  cursor: pointer;
}

.register-field.privacy-policy-checkbox .privacy-link:hover {
  color: #ffffff;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  /* Layout stacking */
  .register-layout {
    flex-direction: column;
    align-items: center; /* Centers items */
  }

  .register-image-container {
    width: 100%;
    height: 40vh;
    flex: none;
  }

  /* Container widths and padding */
  .register-form-container {
    max-width: 100%; 
    margin: 0 auto;
    padding: 20px;
    justify-content: flex-start;
    align-items: center; 
  }

  /* Form widths */
  .register-form {
    max-width: 100%; /* So they both span full width on small screens */
  }

  /* Title sizes */
  .register-title {
    font-size: 28px;
  }

  .phone-code-select {
    width: 25%; /* or whatever keeps it from overflowing */
  }
}
