.game-filter-container {
    padding: 0px 50px; 
    text-align: center;
    color: #1EB62D;
}

/* Title "choose by..." above everything */
.choose-by-title {
    font-family: 'Tomorrow', sans-serif;
    font-size: 40px;
    margin-bottom: 30px;
    color: #1BC429;
}

.carousel-container {
    justify-content: flex-start;
}

/* Styles for the filter type buttons (DATE, LEVEL, PLACE) */
.filter-titles {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;  /* Large gap for desktop */
}


.filter-titles span {
  font-family: 'Tomorrow', sans-serif;
  color: #0F6216;
  font-size: 40px;
  cursor: pointer;

  /* Force a consistent width so text doesn't shift on active state */
  display: inline-block;
  min-width: 160px; /* Adjust as needed so it fits your largest text */
  text-align: center;
}

.filter-titles span.active {
    color: #1BC429;
    font-family: 'Nyxerin', sans-serif;
    font-size: 40px;
}

/* Filter option container */
.filter-option {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
}

/* Ensure the filter options are properly centered */
.filter-options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    position: relative;
}

/* Carousel Header Styling */
.carousel-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

.carousel-title {
    font-size: 20px;
    margin: 0 ;
}

.game-filter-container .carousel-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* Navigation Buttons */
.carousel-nav {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #1EB62D;
    transition: color 0.3s;
}

.carousel-nav:hover {
    color: #148124;
}

/* Container for the filtered games */
.filtered-games {
    display: grid;
    grid-template-columns: repeat(5, 1fr); 
    justify-items: center;
    gap: 20px;
}

/* Center the filtered games when there are fewer than 5 */
.filtered-games.centered {
    justify-content: center;
}



/* --- Responsive adjustments --- */

/* Medium screens */
@media (max-width: 1200px) {
    .filtered-games {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Tablets */
@media (max-width: 768px) {
    .game-filter-container {
        padding: 0 40px; /* reduce horizontal padding on tablets */
    }

    .choose-by-title {
        font-size: 28px; /* smaller title for tablets */
    }

    .filter-titles {
        gap: 30px; /* reduce gap */
    }

    .filter-titles span,
    .filter-titles span.active {
        font-size: 28px; /* smaller filter text */
    }

    .filtered-games {
        grid-template-columns: repeat(2, 1fr);
    }
    .filter-titles span {
        font-size: 28px;
        min-width: 90px;     /* narrower for tablet size */
      }
}

/* Small phones */
@media (max-width: 480px) {
    .game-filter-container {
        padding: 0 20px; /* even less padding on small phones */
    }

    .choose-by-title {
        font-size: 22px; /* smaller title on phones */
        margin-bottom: 20px;
    }

    .filter-titles {
        gap: 30px; /* drastically reduce gap on narrow screens */
        flex-wrap: wrap; /* allow titles to wrap if needed */
    }

    .filter-titles span,
    .filter-titles span.active {
        font-size: 18px; /* smaller filter text */
    }

    .filtered-games {
        grid-template-columns: 1fr; /* single column for game cards */
    }

    .filter-titles span {
        min-width: 60px;     /* narrower for tablet size */
      }

    /* Optional: style for no-games-message if needed */
    .no-games-message {
        font-size: 16px;
        margin-top: 20px;
    }
    .carousel-title {
        font-size: 16px !important;  /* smaller font */
        white-space: nowrap; /* prevent line wrapping */
        overflow: hidden;    /* optional if text is too long */
        text-overflow: ellipsis; /* optional if text is clipped */
    }    
}
