.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000d1c;
  color: #ffffff;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.3);
  font-family: "Tomorrow", sans-serif;
}

.cookie-banner p {
  margin: 0;
  font-size: 0.9rem;
}

.cookie-banner .cookie-learn-more {
  color: #1BC429;
  text-decoration: underline;
  cursor: pointer;
}

.cookie-banner .cookie-learn-more:hover {
  color: #ffffff;
}

.cookie-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

@media (min-width: 600px) {
  .cookie-banner {
    flex-wrap: nowrap;
  }
  .cookie-buttons {
    margin-top: 0;
  }
}
