/* =====================
   Base (Desktop) Styles
   — same as before
   ===================== */
   .game-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #00ff00;
    font-family: 'Tomorrow', sans-serif;
    background-color: #000d1c;
    width: 100vw;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }
  
  .game-info {
    width: 40%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 150px;
    padding-left: 5%;
    padding-right: 5%;
    z-index: 4;
  }
  
  .game-info-sub1 {
    padding-left: 60%;
    white-space: nowrap;
  }
  
  .game-info-sub2 {
    padding-left: 20%;
    margin-top: 15px;
  }
  
  .game-image-container {
    width: 60%;
    position: relative;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .game-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .game-image-container::before {
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 13, 28, 1) 0%,
      rgba(0, 0, 0, 0) 40%
    );
    z-index: 2;
    pointer-events: none;
  }
  
  .noiseeffect {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-image: url('../../assets/images/noise_home_background.avif');
    background-size: cover;
    background-position: center;
    opacity: 0.5;
    pointer-events: none;
  }
  
  .game-time {
    font-size: 26px;
    text-align: right;
  }
  
  .game-date {
    font-size: 16px;
    margin-bottom: 0px;
    text-align: right;
    white-space: nowrap;
  }
  
  .game-game-name {
    font-family: 'Nyxerin', sans-serif;
    font-size: 72px;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
  }
  
  .game-tournament {
    font-size: 26px;
    margin-bottom: 10px;
    color: #22821A;
    text-transform: uppercase;
  }
  
  .game-places-left {
    font-size: 16px;
    margin-top: 20px;
    color: #22821A;
  }
  
  /* "See who's playing!" clickable link */
  .see-whos-playing {
    cursor: pointer;
    color: #22821A;
    transition: color 0.2s ease;
  }
  .see-whos-playing:hover {
    color: #22821A;
    text-decoration: underline;

  }
  
  .sign-up-button-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 30px;
  }
  
  .button-container .button-text {
    white-space: nowrap;
  }
  
  .sign-up-button:hover:not(.disabled), 
  .waitlist-button:hover:not(.disabled) {
    background-color: #00ff00;
    color: #000;
  }
  
  .sign-up-button.disabled,
  .waitlist-button.disabled {
    background-color: transparent;
    color: #fff;
    cursor: not-allowed;
  }
  
  /* Player list styling */
  .player-list {
    margin-top: 15px;
    color: #22821A;
    background: rgba(0, 13, 28, 0.6);
    padding-top: 10px;
    border-radius: 8px;
    max-width: 300px; /* optional limit so it doesn't stretch too wide */
  }
  
  .player-list h4 {
    margin: 0 0 10px 0;
    font-size: 18px;
    color: #00ff00; /* or keep #fff if you prefer */
  }
  
  .player-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .player-list li {
    margin-bottom: 5px;
  }
  
  /* ===============================
     Tablet: Up to 768px wide
     Reorder so image is on top
     =============================== */
  @media (max-width: 768px) {
    .game-info-container {
      flex-direction: column-reverse;
      height: auto;
      width: 100vw;
    }
  
    .game-info {
      width: 100%;
      align-items: center;
      padding: 20px;
      padding-top: 60px;
      text-align: center; /* center text */
    }
  
    .game-info-sub1,
    .game-info-sub2 {
      padding-left: 0;
      text-align: center;
    }
  
    .game-image-container {
      width: 100%;
      height: 50vh;
    }
  
    .game-image {
      height: 50vh;
      object-fit: cover;
    }
  
    .game-time,
    .game-game-name,
    .game-tournament,
    .game-places-left {
      font-size: 1.5rem;
    }
  
    .sign-up-button-container {
      padding-left: 0;
      margin-top: 20px;
    }
  
    .sign-up-button {
      width: 100%;
      padding: 12px;
    }
  
    .game-tournament,
    .game-places-left {
      color: #00ff00;
    }
  
    .player-list {
      max-width: 90%;
    }
  }
  
  /* ===============================
     Phone: Up to 480px wide
     Use the stadium image as
     a background behind text,
     and hide the .game-image-container
     =============================== */
  @media (max-width: 480px) {
    /* Hide the dedicated image container */
    .game-image-container {
      display: none;
    }
  
    /* Decrease overall opacity with an overlay:
       This darkens the image behind the text. */
    .game-info-container::before {
      content: "";
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.3); /* adjust to darken or lighten */
      pointer-events: none;
      z-index: 0;
    }
  
    /* Center text over that background */
    .game-info {
      width: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1; /* ensure text is above overlay */
      padding: 50px;
      margin: 0 auto; /* optional if you want to keep it narrower */
    }
  
    /* Tweak font sizes for phones */
    .game-time,
    .game-date {
      font-size: 18px;
    }
  
    .game-game-name {
      font-size: 36px;
    }
  
    .game-tournament {
      font-size: 18px;
    }
  
    .game-places-left {
      font-size: 14px;
      margin-top: 10px;
    }
  
    .sign-up-button {
      width: 100%;
      padding: 12px;
    }
  
    .player-list {
      max-width: 80%;
      margin: 0 auto;
    }
  }
  