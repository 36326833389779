/* Main layout */
.reset-password-layout {
  display: flex;
  align-items: flex-start; /* Align items to the top */
  background-color: #000d1c;
}

/* Image container */
.reset-password-image-container {
  flex: 0 0 50%; /* Fixed width */
  height: 100vh; /* Fixed height */
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.reset-password-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.reset-password-image-container::before {
  /* Existing styles */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    linear-gradient(
      to left,
      rgba(0, 13, 28, 1) 0%,
      rgba(0, 0, 0, 0) 40%
    ),
    linear-gradient(
      to top,
      rgba(0, 13, 28, 1) 20%,
      rgba(0, 0, 0, 0) 40%
    );
  z-index: 1;
  pointer-events: none;
}

.noise-effect {
  /* Existing styles */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: url('../../assets/images/noise_home_background.avif');
  background-size: cover;
  opacity: 0.5;
}

/* Form container */
.reset-password-form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center;
  padding-top: 80px; /* Adjust as needed */
  background-color: #000d1c;
  z-index: 3;
}

/* Title */
.reset-password-title {
  font-size: 35px; /* Adjusted for consistency */
  color: #1BC429;
  font-family: "Tomorrow", sans-serif;
  text-align: center;
}

/* Form */
.reset-password-form {
  width: 100%;
  max-width: 400px;
}

/* Form Fields */
.reset-password-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.reset-password-field span {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #1BC429;
  font-family: "Tomorrow", sans-serif;
}

.reset-password-input-line {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #1BC429;
  color: #1BC429;
  font-size: 1rem;
  padding-bottom: 5px;
  outline: none;
  width: 100%;
}

.reset-password-input-line::placeholder {
  color: #2C5930;
  font-family: "Tomorrow", sans-serif;
}

/* Password Container */
.password-container {
  position: relative;
  width: 100%;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #1BC429;
  cursor: pointer;
  font-size: 16px;
}

/* Message */
.reset-password-message {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
}

.reset-password-message.success {
  color: #4caf50;
}

.reset-password-message.error {
  color: #f44336;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .reset-password-layout {
    flex-direction: column;
  }

  .reset-password-image-container {
    width: 100%;
    height: 40vh;
    flex: none;
  }

  .reset-password-form-container {
    width: 100%;
    padding-top: 20px; /* Adjust as needed */
  }

  .reset-password-form {
    max-width: 300px;
  }
}
