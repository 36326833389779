.map-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 70px;
    text-align: left;
    color: #1EB62D;
    padding: 100px 0;
}

.map-image {
    width: 500px;
    height: auto;
}

.map-image img {
    width: 100%;
    height: auto;
    display: block;
}

.map-info {
    display: flex;
    flex-direction: column;
}

/* This is where we truncate the address to two lines */
.map-info p {
    margin: 5px;
    font-size: 29px;

    max-width: 400px;         /* pick a width that suits your layout */
    display: -webkit-box;
    -webkit-line-clamp: 3;    /* show only 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    overflow-wrap: break-word; /* or word-wrap: break-word; */
}

.map-info h3 {
    margin: 5px;
    font-size: 29px;
}

.map-info a {
    margin-top: 20px;
    text-decoration: none;
    color: #1EB62D;
    font-size: 28px;
}

.map-info a:hover {
    text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .map-container {
        gap: 50px;
        padding: 80px 20px;
    }
    .map-image {
        width: 350px;
    }
    .map-info a {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .map-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        padding: 60px 20px;
    }
    .map-image {
        width: 100%;
        max-width: 500px;
    }
    .map-info a {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .map-container {
        padding: 40px 10px;
        gap: 20px;
    }
    .map-image {
        width: 100%;
    }
    .map-info a {
        font-size: 16px;
    }
}
