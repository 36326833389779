/* Base container styles for bigger screens */
.game-card-container {
    /* Default/big-screen size: about 20% of carousel, 
       but max out at 236px wide. */
    flex: 0 0 calc(20% - 20px);
    max-width: 236px;
    min-width: 236px; /* or remove if you don't want a min size */
    
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    transition: transform 0.3s ease;
    box-sizing: border-box; /* ensure padding/border counted in total width */
}

/* Card itself: big screen uses ratio-based approach. */
.game-card {
    width: 100%;
    padding-top: 83%; /* Maintain aspect ratio for large screens */
    border-radius: 40px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease;
}

/* Hover effect on bigger screens */
.game-card-container:hover {
    transform: translateY(-10px);
}

/* Absolute wrapper for the image inside .game-card */
.image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* The actual image inside .image-wrapper */
.card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit; 
    display: block;
}

/* Overlay gradient */
.overlay-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(28, 189, 42, 0.8), rgba(0, 0, 0, 0));
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    border-radius: inherit;
}

.game-card:hover .overlay-gradient {
    opacity: 1;
}

/* Text over the image */
.overlay-content {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    color: white;
    z-index: 1;
    transition: color 0.3s ease;
}

.game-card:hover .game-name,
.game-card:hover .game-subtitle {
    color: #0F0326;
}

/* Title and subtitle text inside the overlay */
.game-name {
    font-family: 'Tomorrow', sans-serif;
    font-size: 1.875rem; /* 30px approx. */
    font-weight: 400;
    color: #FFF1E1;
    text-transform: uppercase;
}

.game-subtitle {
    font-family: 'Tomorrow', sans-serif;
    font-size: 0.6875rem; /* 11px approx. */
    font-weight: 400;
    color: #FFF1E1;
    text-transform: uppercase;
    text-align: center;
}

/* Date text below the card */
.game-day {
    font-family: 'Tomorrow', sans-serif;
    font-size: 1rem; /* 16px approx. */
    font-weight: 400;
    color: #1bc429;
    text-align: center;
    margin-top: 10px;
    transition: color 0.3s ease;
}

@media screen and (max-width: 768px) {
    .game-card-container {
        /* Overwrite the big-screen rules */
        flex: 0 0 auto !important;
        width: 142px;
        height: 174px;

        /* Remove min/max constraints from big screen */
        min-width: 0 !important;
        max-width: none !important;
        min-height: 0 !important;
        max-height: none !important;
        border-radius: 20px;
        margin-bottom: 20px;
        transform: none !important; /* If you don't want the hover lift on mobile */
    }
    .game-day {
        font-size: 14px;
    }
    .game-card {
        /* Remove the 83% ratio */
        width: 100% !important;
        height: 100% !important;
        padding-top: 0 !important;
        border-radius: 20px !important;

    }

    /* Optionally remove hover lift effect on mobile */
    .game-card-container:hover {
        transform: none !important;
    }
}
