/* Main Carousel Container */
.carousel-container {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    position: relative;
}

.carousel-container.centered {
    justify-content: center;
    scroll-snap-type: none;
    overflow-x: auto;
}

/* The track that holds all the .game-card-container items */
.carousel-track {
    display: flex;
    gap: 20px;
    transition: transform 0.3s ease-in-out;
    flex-wrap: nowrap;
}

/* Hide the scrollbar for a slicker look */
.carousel-container::-webkit-scrollbar {
    display: none;
}

/* 
   .game-card-container used to be here, 
   but we’ve MOVED that logic into GameCard.css 
   so it doesn't conflict 
*/

/* .game-card-container:hover effect is also in GameCard.css. */

/* Carousel Header and Buttons */
.carousel-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    gap: 20px;
}

.carousel-nav {
    background-color: transparent;
    color: #1EB62D;
    border: none;
    font-size: 40px;
    cursor: pointer;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease;
}

.carousel-nav:hover {
    color: #00ff00;
    text-decoration: none;
}

.carousel-title {
    font-family: 'Tomorrow', sans-serif;
    font-size: 30pt;
    color: #1EB62D;
    text-align: center;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
}

.carousel-container.centered {
    justify-content: center;
    scroll-snap-type: none;
    overflow-x: visible;
}

