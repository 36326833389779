.game-history-container {
  padding: 0;
  color: #1EB62D;
  text-align: center;
  position: relative;
}

.game-filters {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 0;
}

/* The carousel wrapper with horizontal padding for nav arrows */
.carousel-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 0 40px;  /* space for arrows on each side */
  overflow: visible;
}

.carousel-wrapper .carousel-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  color: #1EB62D;
  border: none;
  font-size: 40px;
  cursor: pointer;
  z-index: 10;
  transition: color 0.3s ease;
}

.carousel-wrapper .carousel-nav.left {
  left: 0; /* anchored within the 40px padding */
}

.carousel-wrapper .carousel-nav.right {
  right: 0; /* anchored within the 40px padding */
}

.carousel-wrapper .carousel-nav:hover {
  color: #00ff00;
}

/* Optional: center small carousels if needed */
.carousel-container.centered {
  overflow-x: auto !important;
}

/* Responsive changes */
@media (max-width: 820px) {

  .carousel-wrapper {
    padding: 0 50px;
  }
  .carousel-wrapper .carousel-nav {
    font-size: 28px;
  }


  .game-filters {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px !important;                /* No gap horizontally or vertically */
    margin: 0 !important; 
    padding: 0 20px !important;
    /* optionally align or justify content if needed */
    justify-items: center; 
    align-items: center;
  }
  .game-filters .button-container {
    margin: 0 !important;
    padding: 0 !important;
  }

}
