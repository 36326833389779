.cookies-policy {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #000d1c;
  color: #ffffff;
  font-family: "Tomorrow", sans-serif;
  line-height: 1.6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.cookies-policy h1 {
  font-size: 2.5rem;
  color: #1BC429;
  text-align: center;
  margin-bottom: 20px;
}

.cookies-policy h2 {
  font-size: 1.8rem;
  color: #1BC429;
  margin-top: 30px;
  margin-bottom: 15px;
}

.cookies-policy h3 {
  font-size: 1.5rem;
  color: #1BC429;
  margin-top: 20px;
  margin-bottom: 10px;
}

.cookies-policy p {
  font-size: 1rem;
  margin-bottom: 15px;
}

.cookies-policy ul {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 15px;
}

.cookies-policy li {
  margin-bottom: 10px;
  font-size: 1rem;
}

.cookies-policy a {
  color: #1BC429;
  text-decoration: underline;
}

.cookies-policy a:hover {
  color: #ffffff;
}
