.subscription-info-container {
  text-align: center;
  color: #1EB62D;
  margin-top: 20px;
}

.subscription-info-container h2 {
  font-size: 48px;
  font-family: 'Tomorrow', sans-serif;
  color: #1BC429;
  font-weight: 400;
}

.subscription-info-container p {
  font-size: 20px;
  margin-bottom: 20px;
  color: rgba(30, 182, 45, 0.8);
  font-weight: 400;
}

.price-container {
  margin-bottom: 20px;
}
.price-container .price {
  font-size: 48px;
  font-family: 'Tomorrow', sans-serif;
  color: #ffffff;
  font-weight: 400;
}
.price-container .status {
  font-size: 24px;
  font-family: 'Tomorrow', sans-serif;
  color: rgba(30, 182, 45, 0.8);
  font-weight: 400;
}

/* Buttons - fix spacing */
.subscription-actions {
  display: flex;
  justify-content: center;
  gap: 0px;
  margin-top: 20px;
}

/* 
   Two-rows container for sub vs. one-time 
   We'll name it .subscription-options.two-rows
*/
.subscription-options.two-rows {
  display: flex;
  flex-direction: column;
  gap: 30px; /* vertical gap between rows */
  margin-top: 20px;
  margin-left: 150px;
  margin-right: 150px;

}

/* Each row with 3 columns side by side */
.subscription-options-row {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

/* .option boxes */
.option {
  flex: 1;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  color: #0F6216;
  font-family: 'Tomorrow', sans-serif;
  font-size: 25px;
  line-height: normal;
  font-weight: 400;
  transition: color 0.3s ease;
  border: 2px solid rgba(30, 182, 45, 0.821); /* optional border or styling */
  border-radius: 40px;
}

.option h3 {
  font-size: 35px;
  font-family: 'NYXERIN', sans-serif;
  margin-bottom: 5px;
  color: #1EB62D;
  font-weight: 400;
  margin: 10px
}


/* Hover states */
.option:hover h3,
.option:hover h4,
.option:hover p {
  color: #00ff00;
}

/* Cancel subscription styling */
.cancel-subscription-wrapper {
  margin-top: 50px;
}
.cancel-subscription-link {
  color: rgb(174, 5, 5);
  cursor: pointer;
  display: block;
  margin-bottom: 5px;
}
.cancel-subscription-text {
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .subscription-options.two-rows {
    gap: 20px; /* smaller vertical gap */
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .subscription-options-row {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .option {
    flex: none;
    width: 80%; /* so each .option is narrower on mobile */
    margin: 0 auto;
  }
  .subscription-info-container h2 {
    font-size: 36px;
  }
  .cancel-subscription-wrapper {
    margin-top: 20px;
  }
}
