/* Navbar Styles */

.navbar {
  position: fixed;
  top: 0;
  width: 100%;  /* Full width inside the adjusted viewport */
  height: 124px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #000d1c 0%, rgba(0, 13, 28, 0) 100%);
  overflow: hidden;
  transition: height 0.3s ease;
  
}

.navbar.expanded {
  background: #000d1c !important;
}

/* Navbar Container */
.navbar-container {
  display: flex;
  width: 100%;
  max-width: 1872px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Navbar Sections */
.nav-left,
.nav-center,
.nav-right {
  display: flex;
  align-items: center;
}

.nav-left {
  flex: 1;
  justify-content: flex-start;
  padding-left: 60px;
}

.nav-center {
  flex: 1;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.nav-right {
  flex: 1;
  justify-content: flex-end;
  padding-right: 60px;
}

/* Nav Items */
.nav-items {
  display: flex; /* Ensure items are aligned horizontally */
  align-items: center;
}

.nav-item {
  display: flex; /* Added */
  align-items: center; /* Added */
  color: #1bc429;
  font-family: 'Nyxerin', sans-serif;
  font-size: 35px;
  cursor: pointer;
  z-index: 2;
  margin-left: 20px;
}

.nav-right .nav-item:first-child {
  margin-left: 0;
}

.nav-icon {
  height: 22px;
  width: auto;
  cursor: pointer;
}

/* Hide hamburger menu icon on larger screens */
.nav-menu-icon {
  display: none;
}

/* Dropdown Overlay */
.dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 13, 28, 1) 0%,
    rgba(0, 13, 28, 1) 60%,
    rgba(0, 13, 28, 0.5) 100%
  );
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  box-sizing: border-box;
}

.expanded-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 10%;
}

.line {
  width: 100%;
  text-align: center;
  margin: 0px 0;
}

.line-img {
  width: 100%;
  height: 5px;
  object-fit: cover;
}

.dropdown-item {
  color: #1bc429;
  font-family: 'Nyxerin', sans-serif;
  font-size: 35px;
  margin: 20px 0;
  cursor: pointer;
  text-align: center;
  width: 100%;
}

.dropdown-item:hover {
  color: #00ff00;
}

/* Reset the default Link styles */
.nav-item a {
  text-decoration: none;
  color: inherit;
  font-family: 'Nyxerin', sans-serif;
  font-size: inherit; /* Inherit font size from parent */
}

.nav-item a:hover {
  color: #00ff00;
}

.avatar {
  margin-left: 10px;
  width: 92px;
  height: 86px;
  flex-shrink: 0;
  z-index: 0;
  clip-path: path('M31.1528 0.958984C39.2236 0.958984 46.5589 4.1083 51.9968 9.24508L51.9967 9.24445C58.7406 15.6126 62.4716 15.2635 65.6556 11.8916L65.6558 11.8923C68.4232 8.96271 72.3443 7.13453 76.6924 7.13453C85.0759 7.13453 91.8721 13.9307 91.8721 22.3142C91.8721 28.92 87.6525 34.5404 81.7616 36.6268C75.6781 38.9844 72.7992 43.1017 76.2909 52.7688C77.3841 55.526 77.9849 58.532 77.9849 61.6783C77.9849 75.0353 67.1569 85.8633 53.7999 85.8633C42.4109 85.8633 32.8605 77.9909 30.2935 67.3909C29.3787 64.0061 28.6068 62.6172 25.4508 61.3785C15.9163 61.4629 15.6311 68.9477 16.734 71.3417C17.2167 72.289 17.4889 73.3615 17.4889 74.4976C17.4889 78.3414 14.3729 81.4574 10.5292 81.4574C6.68537 81.4574 3.56937 78.3414 3.56937 74.4976C3.56937 71.1485 5.93491 68.3519 9.08629 67.6876C11.8474 66.9871 18.2055 61.2961 9.69933 52.801L9.70016 52.8014C4.19729 47.3062 0.792969 39.71 0.792969 31.3188C0.792969 14.5515 14.3855 0.958984 31.1528 0.958984Z');
  object-fit: cover;

}


/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .navbar {
    height: 80px;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }

  .nav-left {
    display: none; /* Hides ZEAL on smaller screens */
  }

  .nav-right {
    display: none; /* Hides user profile or right items on smaller screens */
  }

  .nav-center {
    flex: 1;
    display: flex;
    justify-content: center; /* Centers the menu icon */
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .nav-icon {
    height: 22px;
    cursor: pointer;
  }
}

@media (max-width: 480px) {
  .navbar {
    height: 60px;
  }

  .nav-center {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-icon {
    height: 20px;
  }

  .dropdown-item {
    font-size: 22px;
  }
}
