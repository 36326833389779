/* Base Button Container */
.button-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    box-sizing: border-box;
    padding: 0;
    overflow: hidden;
    text-align: center; /* Center text horizontally */
}

/* SVG Container */
.svg-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.button-polygon {
    width: 100%;
    height: 100%;
}

/* Base Button Text */
.button-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #1bc429;
    font-family: 'Nyxerin', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
}

.button-text .primary-text {
    font-size: 22px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.button-text .secondary-text {
    font-family: 'Tomorrow', sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

/* Large Variant */
.button-container.large {
    width: 324px;
    height: 192px;
}
.button-container.large .button-text .primary-text {
    font-size: 30px;
}
.button-container.large .button-text .secondary-text {
    font-size: 20px;
}

/* Medium Variant */
.button-container.medium {
    width: 306px;
    height: 168px;
}
.button-container.medium .button-text .primary-text {
    font-size: 35px;
}
.button-container.medium .button-text .secondary-text {
    font-size: 14px;
}

/* Small Variant (default) */
.button-container.small {
    width: 194px;
    height: 108px;
}
.button-container.small .button-text .primary-text {
    font-size: 15px;
}
.button-container.small .button-text .secondary-text {
    font-size: 12px;
}


/* Adjust line spacing if needed */
.button-text span {
    line-height: 1.2;
}

/* Disabled State */
.button-container.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.button-container.disabled .button-polygon path {
    stroke: #999;
    fill: transparent;
}
.button-container.disabled .button-text {
    color: #999;
}

/* Default State */
.button-container.default .button-polygon path {
    stroke: #1bc429;
    fill: transparent;
}

/* Hover State */
.button-container:hover .button-polygon path {
    stroke: #0F6216;
    fill: #0F6216;
}
.button-container:hover .button-text {
    color: #0f0326;
}

/* Active State */
.button-container.active .button-polygon path {
    stroke: #1bc429;
    fill: #1bc429;
}
.button-container.active .button-text {
    color: #0f0326;
}

/* Inactive State */
.button-container.inactive .button-polygon path {
    stroke: #1bc429;
    fill: transparent;
}
.button-container.inactive .button-text {
    color: #1bc429;
}


@media (max-width: 768px) {
    /* Reduce Large Variant */
    .button-container.large {
        width: 308px; /* Adjust width for small screens */
        height: 172px; /* Adjust height proportionally */
    }
    .button-container.large .button-text .primary-text {
        font-size: 24px; /* Adjust font size for small screens */
    }
    .button-container.large .button-text .secondary-text {
        font-size: 16px; /* Adjust font size */
    }

    /* Reduce Medium Variant */
    .button-container.medium {
        width: 274px; /* Adjust width for small screens */
        height: 152px; /* Adjust height proportionally */
    }
    .button-container.medium .button-text .primary-text {
        font-size: 18px; /* Adjust font size */
    }
    .button-container.medium .button-text .secondary-text {
        font-size: 14px; /* Adjust font size */
    }

    /* Reduce Small Variant */
    .button-container.small {
        width: 180px; /* Adjust width for small screens */
        height: 96px; /* Adjust height proportionally */
    }
    .button-container.small .button-text .primary-text {
        font-size: 16px; /* Adjust font size */
    }
    .button-container.small .button-text .secondary-text {
        font-size: 14px; /* Adjust font size */
    }
}
