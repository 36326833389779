.privacy-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .privacy-modal {
    background-color: #000d1c;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    max-height: 80%;
    overflow-y: auto;
    color: #ffffff;
    font-family: "Tomorrow", sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .privacy-modal h2 {
    color: #1BC429;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .privacy-modal-content {
    font-size: 0.9rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .privacy-modal button {
    display: block;
    margin: 0 auto;
  }
  