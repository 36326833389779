/* Apply to the main container of the page */
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it takes the full height of the viewport */
}

/* Content before footer */
.content {
  flex: 1; /* This ensures that the content takes up remaining space */
}

/* Footer Container */
.footer {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgb(0, 13, 28) 2%,
    rgb(13.5, 104.5, 34.5) 50%,
    rgb(27, 196, 41) 100%
  );
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 300px 20px 50px 20px; /* Adjusted top padding */
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto; /* Push footer to the bottom */
}

/* Noise Effect covering the entire footer */
.footer::before {
  content: '';
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/noise-footer.webp');
  opacity: 0.10;
  pointer-events: none;
  z-index: 1;
}

/* Left Container */
.left-container {
  color: #000c1b;
  margin-left: 20px;
  z-index: 2;
}

/* New Flex Container for ZEAL and Address Info */
.zeal-address-container {
  display: flex;
  align-items: center;
}

/* "ZEAL" Text */
.text-wrapper {
  font-family: 'Nyxerin', sans-serif;
  font-size: 90px;
  font-weight: 400;
  line-height: 0.8;
  margin: 0;
  margin-right: 20px; /* Space between ZEAL and address */
}

/* Address Info */
.address-info {
  display: flex;
  flex-direction: column;
}

.text-i {
  font-family: 'Tomorrow', sans-serif;
  font-size: 22px;
  line-height: 0.8;
  margin: 7px 0;
}

/* Right Container: Social Media Links */
.right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  color: #000c1b;
  margin-right: 20px;
  z-index: 2;
}

/* Social Media Links */
.span-wrapper {
  font-family: 'Tomorrow', sans-serif;
  font-size: 22px;
  line-height: 0.8;
  margin: 7px 0;
  cursor: pointer;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .footer {
    padding: 100px 15px 30px 15px;
    flex-direction: column;
    align-items: center;
  }

  .zeal-address-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .left-container,
  .right-container {
    margin: 20px 0;
  }

  .left-container {
    margin-left: 0;
  }

  .right-container {
    margin-right: 0;
    align-items: center;
    text-align: center;
  }

  .text-wrapper {
    font-size: 70px;
  }

  .text-i,
  .span-wrapper {
    font-size: 20px;
    line-height: 0.8;
    margin: 5px 0;
  }
}

@media screen and (max-width: 480px) {
  .footer {
    padding: 80px 10px 20px 10px;
  }

  .text-wrapper {
    font-size: 50px;
  }

  .text-i,
  .span-wrapper {
    font-size: 16px;
    line-height: 0.8;
  }
}
